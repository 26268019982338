/* global customElements, window */
import WarpElement from '@warp-ds/elements-core';
import { css, html } from 'lit';
import pulse from '@finn-no/pulse-sdk';
import '@warp-ds/elements/components/button';

export class BrazeContentCard extends WarpElement {
    static styles = [
        ...WarpElement.styles, // Adds the global styles
        css`
			/** The line below is where the injected CSS goes, removing it means you get no CSS from the design system **/
			*,:before,:after{--w-rotate:0;--w-rotate-x:0;--w-rotate-y:0;--w-rotate-z:0;--w-scale-x:1;--w-scale-y:1;--w-scale-z:1;--w-skew-x:0;--w-skew-y:0;--w-translate-x:0;--w-translate-y:0;--w-translate-z:0}@media (width>=768px){.md\:h3{font-weight:700;font-size:var(--w-font-size-l);line-height:var(--w-line-height-l)}}.aspect-ratio{aspect-ratio:auto}.static{position:static}.max-h-full{max-height:100%}.max-w-2\/5{max-width:40%}.my-24{margin-top:2.4rem;margin-bottom:2.4rem}.visible{visibility:visible}.object-cover{object-fit:cover}.text-14{font-size:var(--w-font-size-s);line-height:var(--w-line-height-s)}@media (width>=480px){.sm\:p-24{padding:2.4rem}}@media (width>=768px){.md\:my-16{margin-top:1.6rem;margin-bottom:1.6rem}};
		`,
    ];

    static properties = {
        card: { attribute: false },
        braze: { attribute: false },
    };

    isTracked = false;

    constructor() {
        super();

        this.observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    this.onVisible();
                }
            },
            {
                root: null, // Uses the viewport
                threshold: 0.5, // Trigger when at least 50% of the element is visible
            }
        );

        // Fake card for local testing

        // const testCard = {
        // 	title: 'Exclusive Offer for You!',
        // 	description: 'Get 20% off your next purchase. Limited time offer.',
        // 	imageUrl: 'https://cdn.pixabay.com/photo/2024/10/20/14/51/coffee-9135194_1280.jpg',
        // 	extras: {
        // 		cardLocation: 'frontpage',
        // 		cardId: '12345',
        // 		primaryButtonLink: 'https://example.com/shop',
        // 		primaryButtonText: 'Shop Now',
        // 		altText: 'Promotional banner with 20% discount',
        // 	},
        // };

        // DON'T FORGET to comment it out when you are done testing
        // Uncomment for local testing
        // this.card = testCard;

        // Subscribe to braze content card updates, and set the card content on new changes.
        if (window.braze) {
            this.braze = window.braze;

            this.braze.subscribeToContentCardsUpdates((updates) => {
                // Filter cards to ensure only frontpage cards are shown
                const frontpageCards = updates.cards.filter((card) => card.extras?.cardLocation === 'frontpage');
                this.card = frontpageCards[0]; // Set content.
            });
            this.braze.requestContentCardsRefresh();
        }
    }

    connectedCallback() {
        super.connectedCallback();

        this.observe = () => {this.observer.observe(this)};

    }

    onVisible() {
        this.trackViewEvent();
    }

    trackBannerClick() {
        this.braze?.logContentCardClick(this.card);
        pulse.trackEvent({
            type: 'Click',
            intent: 'Open',
            name: 'Braze content card',
            object: {
                type: 'UIElement',
                elementType: 'Button',
                position: `Frontpage:${this.card.extras?.primaryButtonText}`,
                name: this.card.extras?.cardId,
            },
            vertical: { name: 'common_pages' },
        });
    }


    trackViewEvent() {
        if (this.isTracked) return;
        if (this.card) {
            this.isTracked = true;
            this.braze?.logContentCardImpressions([this.card]);
            pulse.trackEvent({
                type: 'View',
                name: 'Braze content card',
                intent: 'Show',
                object: {
                    type: 'UIElement',
                    elementType: 'Card',
                    position: `Frontpage:${this.card.extras?.primaryButtonText}`,
                    name: this.card.extras?.cardId,
                },
                vertical: { name: 'common_pages' },
            });
        }
    }

    render() {
        if (this.card && (this.card.title || this.card.description || this.card.imageUrl)) {
            this.observe();
            return html`
				<section
					id="frontpage-banner"
					card-id="${this.card.extras?.cardId}"
					class="flex justify-between grow relative overflow-hidden rounded-8 shadow-m my-24"
					aria-labelledby="fp-banner-header"
				>
					<div class="p-16 sm:p-24">
						<h2 class="h4 md:h3" id="fp-banner-header">${this.card.title}</h2>
						<p class="inline text-14 md:my-16">${this.card.description}</p>
						<div class="mr-8 mt-16">
							<w-button
								id="frontpage-banner-link"
								variant="primary"
								small
								href="${this.card.extras?.primaryButtonLink}"
								@click="${() => this.trackBannerClick()}"
							>
								${this.card.extras?.primaryButtonText}
							</w-button>
						</div>
					</div>
					<img
						src="${this.card.imageUrl}"
						alt="${this.card.extras?.altText}"
						class="flex flex-1 items-center max-w-2/5 object-cover max-h-full"
						style="aspect-ratio: 10/3;"
					/>
				</section>
			`;
        }
    }
}

if (!customElements.get('braze-content-card')) {
    customElements.define('braze-content-card', BrazeContentCard);
}
