import WarpElement from '@warp-ds/elements-core';
import { css, html } from 'lit';
import pulse from '@finn-no/pulse-sdk';

// SEO categories section.
export class CategoriesSection extends WarpElement {
    static styles = [
        ...WarpElement.styles, // Adds the global styles
        css`
            /** Component styles which are not available outside this component **/
            @media (max-width: 989px) {
                .categories-overflow-gradient {
                    background: linear-gradient(90deg, rgba(214, 214, 214, 0) 0%, rgba(121, 121, 121, 0) 76%, rgba(255, 255, 255, 1) 100%);
                }
            }
            *,:before,:after{--w-rotate:0;--w-rotate-x:0;--w-rotate-y:0;--w-rotate-z:0;--w-scale-x:1;--w-scale-y:1;--w-scale-z:1;--w-skew-x:0;--w-skew-y:0;--w-translate-x:0;--w-translate-y:0;--w-translate-z:0}@media (width>=990px){.lg\:t6{font-weight:700;font-size:var(--w-font-size-xs);line-height:var(--w-line-height-xs)}}.gap-x-\[32px\]{column-gap:32px}.auto-cols-\[22rem\]{grid-auto-columns:22rem}.grid-rows-\[repeat\(18\,2\.8rem\)\]{grid-template-rows:repeat(18,2.8rem)}.overflow-x-auto{overflow-x:auto}.static{position:static}.h-28{height:2.8rem}.h-max{height:max-content}.h-\[548px\]{height:548px}.w-\[calc\(100\%_-_1\.6rem\)\]{width:calc(100% - 1.6rem)}.my-48{margin-top:4.8rem;margin-bottom:4.8rem}.truncate{text-overflow:ellipsis;white-space:nowrap;overflow:hidden}.leading-l{line-height:var(--w-line-height-l)}@media (width>=990px){.lg\:grid-rows-\[repeat\(35\,1\.8rem\)\]{grid-template-rows:repeat(35,1.8rem)}.lg\:grid-rows-\[repeat\(42\,1\.8rem\)\]{grid-template-rows:repeat(42,1.8rem)}.lg\:overflow-hidden{overflow:hidden}.lg\:static{position:static}.lg\:h-auto{height:auto}.lg\:w-auto{width:auto}.lg\:h-\[18\]{height:1.8rem}.lg\:pointer-events-auto{pointer-events:auto}.lg\:text-xs{font-size:var(--w-font-size-xs);line-height:var(--w-line-height-xs)}.lg\:leading-s{line-height:var(--w-line-height-s)}};
        `,
    ];

    connectedCallback() {
        super.connectedCallback();

        if (window.innerWidth < 990) {
            this.setupCategoryGradientBehavior();
        }
    }

    setupCategoryGradientBehavior() {
        function getScrollParameters(element) {
            const { scrollLeft, scrollWidth, clientWidth } = element;

            const width = scrollWidth - clientWidth;

            const fraction = (width - scrollLeft) / 50;

            return { width, fraction, scrollLeft };
        }

        const gradientDiv = this.shadowRoot?.querySelector('.categories-overflow-gradient');

        const categoryDiv = this.shadowRoot?.querySelector('.categories-scrollable-container>div');

        if (gradientDiv && categoryDiv) {
            // Setup scrolling.
            const container = this.shadowRoot?.querySelector('.categories-scrollable-container');

            categoryDiv.addEventListener('scroll', (e) => {
                container.setAttribute('style', 'margin: 0 -1.6rem');

                const { width, fraction, scrollLeft } = getScrollParameters(e.target);

                if (fraction <= 1) {
                    gradientDiv.style.opacity = fraction;
                }

                if (scrollLeft / width < 0.7) {
                    gradientDiv.style.opacity = 1;
                }

                if (scrollLeft === 0) {
                    container.removeAttribute('style');
                }
            });
        }
    }

    // Track the click on a category (used for both main (header) sections and subsections).
    trackCategoryClick(trackingData) {
        pulse.trackEvent({
            type: 'Click',
            intent: 'Open',
            name: trackingData.name,
            object: {
                type: 'UIElement',
                elementType: trackingData.object.element_type,
                name: trackingData.object.name,
            },
            target: trackingData.target,
            vertical: {
                '@type': trackingData.vertical.type,
                name: trackingData.vertical.name,
            },
        });
    }

    get _rowClass() {
        return this.brand === 'DBA'
            ? 'lg:grid-rows-[repeat(42,1.8rem)]'
            : 'lg:grid-rows-[repeat(35,1.8rem)]';
    }

    static properties = {
        brand: { type: String, attribute: 'brand', reflect: true },
        sections: { type: Array, attribute: 'sections', reflect: true },
    };

    render() {
        // HTML rows.
        const items = [];

        if (this.sections) {
            const { sections } = this;

            // Create html items from sections and categories.
            sections.forEach((section) => {
                const { tracking, destinations, label } = section.header_category;
                const textColor = `${section.categories?.length === 0 ? 's-text-link' : 's-text'}`;

                items.push(
                    html`<a
                        class="truncate h-max s-text t4 leading-l lg:t6 lg:leading-s ${textColor}"
                        href=${destinations.url}
                        @click=${() => this.trackCategoryClick(tracking)}
                    >
                        ${label}
                    </a>`,
                );

                section.categories.forEach((category) => {
                    items.push(
                        html`<a
                            href="${category.destinations.url}"
                            class="truncate h-max text-m leading-l lg:text-xs lg:leading-s"
                            @click=${() => this.trackCategoryClick(category.tracking)}
                        >
                            ${category.label}
                        </a>`,
                    );
                });

                items.push(html`<span class="h-28 lg:h-[18]"></span>`);
            });

            return html`
                <div class="categories-overflow-gradient w-[calc(100%_-_1.6rem)] h-[548px] z-10 absolute pointer-events-none lg:static lg:pointer-events-auto lg:h-auto lg:w-auto"></div>
                <div class="categories-scrollable-container">
                    <div class="overflow-x-auto lg:overflow-hidden">
                        <div class="grid grid-flow-col grid-rows-[repeat(18,2.8rem)] auto-cols-[22rem] gap-x-[32px] ${this._rowClass} my-48" data-categories-pulse-wrapper>${items}</div>
                    </div>
                </div>
            `;
        }
    }
}

if (!customElements.get('categories-section')) {
    customElements.define('categories-section', CategoriesSection);
}
